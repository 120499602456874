import Vue from 'vue';
import Vuex from 'vuex';

// modules
import authentication from '../modules/authentication/store';
import droneView from '../modules/droneView/store';
import missions from './missions';
import window from './window';

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  modules: {
    authentication,
    droneView,
    missions,
    window,
  },
});

<template>
  <div id="app-container">
    <navbar />
    <div class="router-view">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Navbar from './modules/navbar/components/Navbar.vue';

export default {
  components: {
    Navbar,
  },
  mounted() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  },
  methods: {
    updateWindowDimensions() {
      this.$store.dispatch('window/updateDimensions');
    },
  },
};
</script>

<style lang="scss">
@import './src/style/colors.scss';

#app-container {
  position: fixed;
  top: 0; left: 0; right: 0; bottom: 0;
  display: flex;
  flex-direction: column;
  background: $appBgColor;
}
.router-view {
  flex: 1 1 0;
  min-height: 0;
  overflow: hidden;
  display: flex;
}
</style>
